const Module = () => import("./Module.vue");
const Transactions = () => import("./views/Transactions");

const moduleRoute = {
  name: "transactions_main",
  path: "/transactions",
  component: Module,
  children: [
    {
      path: "overview",
      name: 'transactions_overview',
      component: Transactions
    }
  ]
};

export default router => {
  router.addRoute(moduleRoute);
};