import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ApiService from "@/services/api.service";
import { registerModules } from "./register-modules";

import authModule from "./modules/auth";
import mealMenuModule from "./modules/mealmenu";
import transactionsModule from "./modules/transactions";
import orderModule from "./modules/orders";
import kitchenModule from "./modules/kitchen";

registerModules({
  auth: authModule,
  mealMenu: mealMenuModule,
  transactions: transactionsModule,
  orders: orderModule,
  kitchen: kitchenModule
});

// API service init
ApiService.init();

// Global 3d party plugins
import PerfectScrollbar from 'perfect-scrollbar';
window.PerfectScrollbar = PerfectScrollbar;

// Vue 3rd party plugins
import "@/plugins/bootstrap-vue";
import "@/plugins/vue-toasted";
import "@/plugins/vue-meta";
import i18n from "@/plugins/vue-i18n";
import "@/plugins/moment";
import "@/plugins/perfect-scrollbar";

/** ---------- */

// APP config
Vue.prototype.$terminalType    = 'internet'  // terminal - internet
Vue.prototype.$websocketServer = 'ws://jidlo-term.hssilherovice.cz:1880/ws'  // WS address + port

/** ---------- */

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')


