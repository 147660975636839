// mutation types
export const SET_TRANSACTION_SEARCH = "setTransactionSearch";

const state = {
  transactionSearch: {
    dateStart: null,
  },
};

const actions = {
};

const getters = {
  getTransactionSearch(state) {
    return state.transactionSearch;
  },
};

const mutations = {
  [SET_TRANSACTION_SEARCH](state, data) {
    state.transactionSearch = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};