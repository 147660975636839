// router
import router from "./router";
import store from "./store/";

// i18n translates
import { i18nCS } from "./i18n/cs";
import { i18nEN } from "./i18n/en";

export default {
  store,
  router,
  i18nCS,
  i18nEN
};