import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "./jwt.service";
import router from "../router";
import i18n from "@/plugins/vue-i18n";


/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    // Vue.axios.defaults.baseURL = 'http://127.0.0.1:8000/api/meal-system/app/';
    Vue.axios.defaults.baseURL = 'https://terminal.hssilherovice.cz/backend/api/meal-system/app/';
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
    //console.log(Vue.axios.defaults.headers);
  },

  setFileHeader() {
    Vue.axios.defaults.headers.common["Authorization"] = `Bearer ${JwtService.getToken()}`;
    Vue.axios.defaults.headers.common["Content-Type"] = 'multipart/form-data';
    //console.log(Vue.axios.defaults.headers);
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch(error => {
      console.log(error)
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource) {
    return Vue.axios.get(`${resource}`).catch(error => {
      console.log(error)
    });
  },

  apiGet(resource) {
    return Vue.axios.get(`${resource}`).catch(error => {
      if(error.response.status === 401){
        router.push({ name: 'login' })
        Vue.toasted.error(i18n.t('message.' + error.response.data.message));
      }
      if(error.response.status === 403){
        router.push({ name: 'dashboard' })
        Vue.toasted.error(i18n.t('message.' + error.response.data.message));
      }
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  apiPost(resource, params) {
    return Vue.axios.post(`${resource}`, params)
      .then(response => {
        Vue.toasted.success(i18n.t('message.' + response.data.message));
        return response;
      });
  },

  apiDownloadFile(resource)
  {
    return axios({
      url: resource,
      method: 'POST',
      responseType: 'arraybuffer',
      }).then((response) => {
          var fileName = response.headers["file-name"];
          fileName = decodeURIComponent(fileName);
          fileName = fileName.replaceAll("+", " ")

          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', fileName);
          document.body.appendChild(fileLink);
          fileLink.click();

      });
  },

  apiOpenFile(resource)
  {
    axios({
      url: resource,
      method: 'POST',
      responseType: 'arraybuffer',
      }).then((response) => {

          var contentType = response.headers["content-type"];
          var file = new Blob([response.data], {type: contentType});
          var fileURL = URL.createObjectURL(file);
          
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('target', '_blank');
          document.body.appendChild(fileLink);
          fileLink.click();
      });
  },
};

export default ApiService;
