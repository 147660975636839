import router from "./router";
import store from "./store";
import i18n from "@/plugins/vue-i18n";

const registerModule = (module, name) => {

  // Merge router
  if(module.router) {
    module.router(router);
  }
  // Join store
  if (module.store) {
    store.registerModule(name, module.store);
  }

  // Merge i18n from module
  i18n.mergeLocaleMessage('cs', module.i18nCS);
  i18n.mergeLocaleMessage('en', module.i18nEN);
};

export const registerModules = modules => {
  Object.keys(modules).forEach(moduleKey => {
    const module = modules[moduleKey];
    registerModule(module, moduleKey);
  });
};