import Vue from 'vue'
import Vuex from 'vuex'
import ApiService from "../services/api.service"
import JwtService from "../services/jwt.service"
import pagination from "./pagination.module"
import router from "../router"
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGIN_CARD = "loginCard";
export const LOGOUT = "logout";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setDiner";
export const SET_ERROR = "setError";

const store = new Vuex.Store({
plugins: [createPersistedState()],
state : {
  errors: null,
  diner: {},
  isAuthenticated: !!JwtService.getToken()
},
modules: {
  pagination
},
getters : {
  currentdiner(state) {
    return state.diner;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
},

actions : {
  [LOGIN](context, credentials) {
    console.log(credentials);
    return new Promise(resolve => {
      ApiService.post("/auth/login", credentials)
        .then(({ data }) => {
          if(data.token){
            context.commit(SET_AUTH, data);
            resolve(data);
          }
          else{
            context.commit(SET_ERROR, ['login.unathicated']);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, [response.data.message]);
        });
    });
  },
  [LOGIN_CARD](context, credentials) {
    return new Promise(resolve => {
      ApiService.post("/auth/card-authenticate", credentials)
        .then(({ data }) => {
          if(data.token){
            context.commit(SET_AUTH, data);
            resolve(data);
          }
          else{
            context.commit(SET_ERROR, ['login.unathicated']);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, [response.data.message]);
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("/auth/verify")
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
        })
        .catch(() => {
          context.commit(PURGE_AUTH);
          router.push({ name: 'login'});
        });
    } else {
      context.commit(PURGE_AUTH);
    }
  }
},

mutations : {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, data) {
    state.isAuthenticated = true;
    state.diner = data.diner;
    state.errors = {};
    JwtService.saveToken(data.token);
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.diner = {};
    state.errors = {};
    JwtService.destroyToken();
  }
}
})
  
export default store