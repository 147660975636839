const Module = () => import("./Module.vue");

const moduleRoute = {
  name: "login",
  path: "/auth/login",
  component: Module
};

export default router => {
  router.addRoute(moduleRoute);
};