const Module = () => import("./Module.vue");

const moduleRoute = {
  name: "mealmenu",
  path: "/jidelni-listek",
  component: Module,
  children: [
    {
      path: "/jidelni-listek/:id",
      name: 'mealmenu_detail',
      component: Module,
    }
  ]
};

export default router => {
  router.addRoute(moduleRoute);
};