// mutation types
export const SET_ORDER_LIST_SEARCH = "setOrderListSearch";
export const SET_ORDER_NEW_SEARCH = "setOrderNewSearch";
export const SET_ORDER_MONTH_SEARCH = "setOrderMonthSearch";

const state = {
  orderListSearch: {
    dateStart: null,
  },
  orderNewSearch: {
    dateSearch: null
  },
  orderMonthSearch: {
    firstDay: null
  }
};

const actions = {
};

const getters = {
  getOrderListSearch(state) {
    return state.orderListSearch;
  },
  getOrderNewSearch(state) {
    return state.orderNewSearch;
  },
  getOrderMonthSearch(state) {
    return state.orderMonthSearch;
  },
};

const mutations = {
  [SET_ORDER_LIST_SEARCH](state, data) {
    state.orderListSearch = data;
  },
  [SET_ORDER_NEW_SEARCH](state, data) {
    state.orderNewSearch = data;
  },
  [SET_ORDER_MONTH_SEARCH](state, data) {
    state.orderMonthSearch = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};