// Czech
export const i18nCS = {
    meta: {
        transactions: "Transakce konta"
    },
    menu: {
        transactions: "Přehled transakcí"
    },
    message: {

    },
    transactions: {
        label: {
            payment_date: "Datum platby",
            transaction_type: "Typ transakce",
            food_type: "Typ jídla",
            order_on_day: "Objednávka na den",
            price: "Částka"
        },
        title: {
            type_MEAL_PAYMENT: "Platba za stravné",
            type_MEAL_PAYMENT_REFUND: "	Vratka za stravné",
            type_CONTRIBUTION_PAYMENT: "Zrušení příspěvku",
            type_MEAL_PREPURCHASE: "Předobjednávka stravy",
            type_DEPOSIT: "Nabití konta",
            type_WITHDRAW: "Výběr z konta",
            none_found: "Žádné transakce nebyly v tomto období nalezeny."
        }
    }

}