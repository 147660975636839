import Vue from "vue";
import Toasted from 'vue-toasted';

const settings = {
    position: 'bottom-center',
    duration: 3000,
    singleton: true
}

Vue.use(Toasted, settings);
