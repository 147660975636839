<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<style lang="scss">
  // Core styles scss
  @import "assets/sass/style";

  // 3rd party plugins css
  @import "~perfect-scrollbar/css/perfect-scrollbar.css";
  @import "~@fortawesome/fontawesome-free/css/all.css";
</style>

<script>


export default {
  name: "PIXIOCRM",
  components: {
     
  },
  metaInfo () { 
      return { title: '-', titleTemplate: '%s | ' + this.settings.project_name } 
  },
  data() {
    return {
      settings: {
        project_name: "Jídelní terminál",
      },
    };
  }
};
</script>


