// Czech
export const i18nCS = {
    meta: {
        login: "Přihlášení do systému"
    },
    auth: 
    {
        button: {
            login: "Přihlásit se do systému"
        },
        label: {
            name: "Zadejte jméno",
            surname: "Zadejte příjmení",
            personal_number: "Zadejte osobní číslo"
        },
        title: {
            login: "Přihlášení",
            put_card_to_reader: "Přiložte kartu ke čtečce",

        },
        placeholder: {
            first_name: "Zadejte vaše jméno",
            last_name: "Zadejte vaše příjmení",
            card_number: "Zadejte osobní číslo / číslo karty",
        },
        login: {
            invalid_credentials: "Neplatné přihlašovací údaje"
        }
    }
}