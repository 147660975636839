const Module = () => import("./Module.vue");

const moduleRoute = {
  name: "kitchen_overview",
  path: "/kitchen",
  component: Module
};

export default router => {
  router.addRoute(moduleRoute);
};