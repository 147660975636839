import Vue from 'vue'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
 
moment.locale('cs')

Vue.use(VueMoment, {
    moment,
})

export default moment;