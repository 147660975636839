// Czech
export const i18nCS = {
    meta: {
        week_menu: "Jídelní lístek"
    },
    menu: {
        mealmenu: "Jídelní lístek"
    },
    mealmenu: {
        title: {
            lunch_menu: "Jídelní lístek",
            alergens: "Alergeny",
            valid_to: "Jídelní lístek platný ke dni ",
            no_set_meal: "Žádný strava nenastavena."
        }
    }
}