// Czech
export const locale = {
    translator: {
      select: "Zvolte jazyk systému"
    },
    language: {
      czech: "Čeština",
      english: "English"
    },
    main: {
      app_name: "Jídelní terminál",
      app_title: "Objednávkový terminál",
      company_name: "PIXIO s.r.o.",
      company_phone: "+420 604 639 441",
      company_email: "obchod@pixio.cz",
      company_web: "www.pixio.cz",
      client_name: "Střední škola hotelnictví, gastronomie <br />a služeb SČMSD Šilheřovice, s.r.o.",
      client_phone: "595 054 106",
      client_email: "sekretariat@hssilherovice.cz",
    },
    menu: {
      main_menu: "Hlavní menu",
    },
    message: {
        unauthenticated: "Přihlášení neauthorizováno. Prosím, znova se přihlašte.",
        login: {
          login_succes: "Přihlášení bylo úspěšné."
        }
    },
    button: {
      login: "Přihlásit se",
      logout: "Odhlásit se",
      cancel: "Zrušit"
    },
    pagination: {
      showing: "Zobrazuji",
      records: "zázmamů",
      from: "z",
    },
    title: {
      privacy_policy: "Ochrana osobních údajů",
      all_rights_reserved: "Všechny práva vyhrazena",
      support: "Podpora",
      email: "Email",
      phone: "Telefon",
      technical_operator: "Technický provozovatel"
    },
    diner: {
      conto_status: "Stav konta"
    }
}