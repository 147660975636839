// Czech
export const i18nCS = {
    meta: {
        orders_new: "Objednávka stravy",
        orders_overview: "Přehled objednávek", 
    },
    menu: {
        orders_new: "Objednávka stravy",
        orders_overview: "Přehled objednávek",
    },
    message: {
        diner_order: {
            already_have: "Již objednána jiná varianta typu jídla.",
            created: "Objednávka stravy vytvořena!",
            canceled: "Objednávka stravy zrušena!",
            can_no_longer_cancel: "Objednávka již nemůže být zrušena.",
            preorder_created: "Předobjednávka stravy byla vytvořena!",
            preorders_created: "Předobjednávky stravy byly vytvořeny!",
            you_already_have_this_one: "Pro tento den již máte objednanou stravu.",
            not_enought_money: "Na kontě není dostatek financí.",
            can_no_longer_be_ordered: "Jídlo již nelze objednat.",
            can_not_be_purchased: "Jídlo již nelze zakoupit.",
            move_to_auction: "Objednávka vložena na burzu",
            purchased: "Objednávka zakoupena z burzy!",
            not_set_food_type: "Pro předobjednávky není nastaven druh jídla."
        },
        meal_menu: {
            not_found: "Nebyla nalezena varianta jídla."
        }
    },
    orders: {
        label: {
            on_day: "Na den",
            food_type: "Druh jídla",
            order_type: "Typ objednávky",
            food_name: "Název jídla",
            food_price: "Cena jídla",
            order_source: "Zdroj objednávky"
        },
        button: {
            order_month: "Objednat celý měsíc"
        },
        title: {
            previous_week: "Předchozí týden",
            next_week: "Následující týden",
            total: "Celkem",
            alergens: "Alergeny",
            week_order: "Týden",
            month: "Měsíc",
            source_internet: "Internet",
            source_terminal: "Terminál",
            help: "Nápověda",
            help_ordered_variant: "Objednaná varianta stravy",
            help_variant_to_order: "Varianta k objedání",
            help_variant_to_cancel_order: "Pro <span class='fw-bold' style='color: #800e0e'>zrušení</span>objednávky stačí znovu kliknout na <span class='meal-checkbox meal-checkbox-active d-inline-block' style='transform: translateY(25%)'></span>.",
            download_pdf_mealmenu: "Stáhnout PDF jídelníček",
            none_found: "Žádné objednávky nebyly v tomto období nalezeny.",
            no_variant_exists: "Pro druh jídla neexistuje žádná varianta.",
            no_food_type_exists: "Pro tento den neexistuje žádný druh jídla.",
            confirm_month_order_title: "Potvrzení objednávky na celý měsíc",
            confirm_month_order_text: "Opravdu chcete objednat celý měsíc? Jednotlivé dny se objednají do výše Vašeho konta.",
            preorder: "Předobjednávka",

            type_PREORDER: "Předobjednávka",
            type_CANCELED: "Zrušená",
            type_AUCTION: "V aukci",
            type_NEW: "Nová",

            create_order: "Objednat stravu",
            order_new: "Nová objednávka",
            order_sold_waiting: "Čeká na burze",
            order_completed: "Objednávka vyzvednuta",
            order_auction_waiting: "Čeká na prodej na burze",
            order_auction_sold: "Prodáno na burze",
            order_canceled: "Objednávka zrušena",
            create_new_dinner_title: "Založení nového strávníka",
            order_ordered: "Objednaná strava",
            order_cant_ordered: "Již není možné objednat",
            order_cancel: "Zrušit objednávku",
            order_sell: "Vložit na burzu",
            order_can_be_canceled: "Objednávku lze zrušit",
            order_can_be_sell: "Objednávku lze vložit na burzu",
            order_cannot_be_canceled: "Již nelze zrušit ani prodat",
            order_buy_from_auction: "Zakoupit z burzy",
            num_of_items_on_auction: "Je k dispozici <strong>{item}</strong> položky/položek na burze",
            show_all_orders_on_auction: "Zobrazit položky"

        }
    }
    
}