// mutation types
export const SET_ITEMS_PER_PAGE = "setItemsPerPage";

const state = {
   pagination: {
       itemsPerPage: 15
   }

};

const actions = {
};

const getters = {
  getPagination(state) {
    return state.pagination;
  },
};


const mutations = {
  [SET_ITEMS_PER_PAGE](state, data) {
    state.pagination.itemsPerPage = data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
