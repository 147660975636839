const Module = () => import("./Module.vue");
const OrdersNew = () => import("./views/OrdersNew");
const OrdersNewMonth = () => import("./views/OrdersNewMonth");
const OrdersOverview = () => import("./views/OrdersOverview");

const moduleRoute = {
  name: "orders",
  path: "/orders",
  component: Module,
  children: [
    {
      path: "/orders/new",
      name: 'orders_new',
      component: OrdersNew,
    },
    {
      path: "/orders/new/month",
      name: 'orders_new_month',
      component: OrdersNewMonth
    },
    {
      path: "/orders/overview",
      name: 'orders_overview',
      component: OrdersOverview
    }
  ]
};

export default router => {
  router.addRoute(moduleRoute);
};